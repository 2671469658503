<template>
	<div class="container">
		<el-card class="card-box">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px" class="demo-ruleForm">
				<el-form-item label="规则名称:" prop="name">
					<el-input v-model="ruleForm.name" :disabled="id?true:false" maxlength="15" style="width: 300px;" placeholder="请输入规则名称,最多15个字">
					</el-input>
				</el-form-item>
				
				<el-form class="form1" :model="ruleForm" :rules="rules" ref="ruleForm1" label-width="90px">
					<el-form-item label="打上标签:" prop="tag" required>
						<div style="min-height: 36px;">
							<el-tag :closable="id?false:true" type="info" style="margin-right: 10px;"
								v-for="(item, index) in tagList" :key="item.TagDetailId" @close="handleCloseTag(index)">
								{{item.Name}}
							</el-tag>
							<el-link type="primary" style="line-height: 20px;" @click="addTagVisible=true" v-if="!id">选择标签
							</el-link>
						</div>
						<div style="line-height: 18px;color: #999;font-size: 13px;">
							给符合以下配置条件的客户打上选中的标签
						</div>
					</el-form-item>
				</el-form>
				
				<el-form-item label="更新方式:" prop="UpdateWay">
					<el-radio v-model="ruleForm.UpdateWay" :label="0"  :disabled="id?true:false">手动更新</el-radio>
					<el-radio v-model="ruleForm.UpdateWay" :label="1" :disabled="id?true:false">自动更新</el-radio>
					
					<div style="line-height: 18px;color: #999;font-size: 13px;">
						手动更新：在规则启用时，你可以在自动打标签管理列表上，手动点击更新，操作后，系统将根据规则中配置的条件更新客户标签<br/>
						自动更新：在规则启用时，系统将根据规则中配置的条件，每天/每周/每月自动更新客户标签，直到规则到期停止；自动更新时间在凌晨1点
					</div>
				</el-form-item>
				<el-form-item label="更新频次:" prop="AutoUpdatePeriodType" v-if="ruleForm.UpdateWay===1">
					<el-select v-model="ruleForm.AutoUpdatePeriodType" placeholder="请选择" style="width: 160px;"  :disabled="id?true:false" @change="selectChange">
						<el-option v-for="item in updateList" :key="item.id" :label="item.label" :value="item.id"></el-option>
					</el-select>
					<el-select v-model="ruleForm.AutoUpdatePeriodNum" placeholder="请选择" style="width: 160px;margin-left: 5px;"  :disabled="id?true:false" v-if="ruleForm.AutoUpdatePeriodType===1">
						<el-option v-for="item in weeks" :key="item.id" :label="item.label" :value="item.id"></el-option>
					</el-select>
					<el-select v-model="ruleForm.AutoUpdatePeriodNum" placeholder="请选择" style="width: 160px;margin-left: 5px;" :disabled="id?true:false" v-if="ruleForm.AutoUpdatePeriodType===2">
						<el-option v-for="item in days" :key="item.id" :label="item.label" :value="item.id"></el-option>
					</el-select>
					凌晨1点
				</el-form-item>
				
				<el-form-item label="条件设置:" v-if="!id">
					<el-link type="primary" style="line-height: 20px;" href="https://jusnn6k8al.feishu.cn/docs/doccn6iH4wDH2JHLZooTTX7UpYb" target="_blank">查看示例</el-link>
				</el-form-item>
				
				<!-- <el-form-item label="规则设置:" v-if="!id">
					<el-link type="primary" style="line-height: 20px;" @click="ruleVisible=true">推荐规则</el-link>
				</el-form-item> -->
			</el-form>
			
			
			
			<div class="tag-wraper">
				<tag ref="tagMod" :defaultList="list" :disabled="id?true:false"></tag>
			</div>
			
		</el-card>

		<addTagPop :dialogTag="addTagVisible" tagkey="TagDetailId" title="选择标签" :selected="tagList" @close="addTagVisible=false" @confirm="handleSelect">
		</addTagPop>

		<el-dialog title="推荐规则" :visible.sync="ruleVisible" width="700px">

			<div class="rule-title">你可以从下方选择自己商城内适用的规则</div>

			<div class="rule-item" v-for="(item, index) in normalList" :key="index">
				<div class="txt"><span style="padding-right: 10px;">为</span>{{item.title}}</div>
				<el-link type="primary" @click="handleUse(item.data)">使用该规则</el-link>
			</div>

			<span slot="footer" class="dialog-footer">
			</span>
		</el-dialog>

		<div class="footer" v-if="!id">
			<el-button @click="handleBack">返回</el-button>
			<el-button type="primary" @click="handleSave">保存</el-button>
		</div>
	</div>
</template>

<script>
	import {
		tagRuleConfigedit,
		tagRuleConfigeinfo,
		getTemplateParam
	} from '@/api/sv1.0.0.js';
	
	import {
		autoMarketingTaskConfiginit
	} from '@/api/sv3.0.0.js';

	import {
		enumList
	} from '@/api/TurnTomySelf.js';
	import config from '@/config/index';

	import {
		mapGetters
	} from 'vuex'

	import addTagPop from '@/views/Customer/components/tagging.vue';
	import tag from './components/tag.vue';
	
	import {templateList} from './js/normalTemplate.js'

	export default {
		components: {
			addTagPop,
			tag
		},
		computed: {
			...mapGetters([
				"mallInfo"
			]),
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				ruleForm: {
					name: '',
					UpdateWay: 0,
					AutoUpdatePeriodType: 1,
					AutoUpdatePeriodNum: 1
				},
				rules: {
					name: [{
						required: true,
						message: '请输入规则名称',
						trigger: 'blur'
					}]
				},

				list: [],
				addTagVisible: false,
				tagList: [],
				id: '',

				normalList: [],
				ruleVisible: false,
				
				updateList: [{
					id: 0,
					label: '每天（自然日）'
				},
				{
					id: 1,
					label: '每周（自然周）'
				},
				{
					id: 2,
					label: '每月（自然月）'
				}],
				days: [],
				weeks: [{
					id: 1,
					label: '周一'
				},{
					id: 2,
					label: '周二'
				},{
					id: 3,
					label: '周三'
				},{
					id: 4,
					label: '周四'
				},{
					id: 5,
					label: '周五'
				},{
					id: 6,
					label: '周六'
				},{
					id: 7,
					label: '周日'
				}]
			};
		},
		beforeMount() {
			
			this.mod = localStorage.getItem('mlmzDistributionModel');
			
			//查看详情
			if (this.$route.query.id) {
				this.id = this.$route.query.id;
				this.getDetail(this.id)
			}
			
			//复制
			if (this.$route.query.copyId) {
				this.copyId = this.$route.query.copyId;
				this.getDetail(this.copyId)
			}
			
			for (let i=1;i<=28;i++){
				this.days.push({
					id: i,
					label: i+'号'
				})
			}
			
			//如果是模板， 取模板数据
			if (this.$route.query.templateId){
				
				// 6\7\11 三个模块 需要取动态数据
				this.getParams(this.$route.query.templateId).then(res=>{
					this.getLevels().then(levData=>{
						templateList.map(item=>{
							
							if (item.templateId == this.$route.query.templateId){
								
								var data = item.data;
								if(item.templateId === 6){ //高质量推手
									var num = res.CommissionMemberCount ? +(res.CommissionAwardMoney / res.CommissionMemberCount).toFixed(2) : 0;
									num = num===0?'0':num;
									data.TagRuleConfigDetailList[1].Num = num;
									
									var list = [];
									if (this.mod === '0'){
										levData.MemberDistributorRoleList.map(item=>{
											if (item.Id){
												list.push({
													RelationId: item.Id
												})
											}
										})
									}else{
										levData.AgentRoleList.map(item=>{
											if (item.Id){
												list.push({
													RelationId: item.Id
												})
											}
										})
									}
									data.TagRuleConfigDetailList[0].TagRuleConfigDetailRelationIdList = list;
								}
								if(item.templateId === 7){
									var num = res.PayOrderMemberCount ? +(res.PayOrderCount / res.PayOrderMemberCount).toFixed(2) : 0;
									var num1 = res.PayOrderMemberCount ? +(res.PayOrderMoney / res.PayOrderMemberCount).toFixed(2) : 0;
									num = num===0?'0':num;
									num1 = num1===0?'0':num1;
									data.TagRuleConfigDetailList[1].Num = Math.floor(+num);
									data.TagRuleConfigDetailList[0].Num = num1;
								}
								if(item.templateId === 10){ //活跃推手
									var list = [];
									if (this.mod === '0'){
										levData.MemberDistributorRoleList.map(item=>{
											if (item.Id){
												list.push({
													RelationId: item.Id
												})
											}
										})
									}else{
										levData.AgentRoleList.map(item=>{
											if (item.Id){
												list.push({
													RelationId: item.Id
												})
											}
										})
									}
									data.TagRuleConfigDetailList[0].TagRuleConfigDetailRelationIdList = list;
								}
								if(item.templateId === 11){
									var num = res.GrowthValue === 0 ? '0':res.GrowthValue;
									data.TagRuleConfigDetailList[1].Num = res.GrowthValue;
								}
								
								
								this.ruleForm.name = data.Name;
								this.ruleForm.UpdateWay = data.UpdateWay;
								this.ruleForm.AutoUpdatePeriodType = data.AutoUpdatePeriodType;
								this.ruleForm.AutoUpdatePeriodNum = data.AutoUpdatePeriodNum;
								this.list = data.TagRuleConfigDetailList;
							}
						})
					})
					
				})
				
				
			}

			// this.normalList = [];
			// this.getNormalRuleList();
		},
		methods: {
			async getLevels(){
				return new Promise(async (reslove, reject)=>{
					try{
						const res = await autoMarketingTaskConfiginit();
						reslove(res);
					}catch(e){
						reject()
					}
				})
			},
			getParams(templateId){
				return new Promise(async (reslove, reject)=>{
					try{
						
						var type = 0;
						if (templateId == 6){
							type = 3;
						}
						if (templateId == 7){
							type = 1;
						}
						if (templateId == 11){
							type = 2;
						}
						
						const res = await getTemplateParam({
							TemplateType: type
						});
						reslove(res);
					}catch(e){
						reject()
					}
				})
			},
			selectChange(){
				this.ruleForm.AutoUpdatePeriodNum = 1;
			},
			async getDetail(id) {
				try {
					const res = await tagRuleConfigeinfo({
						TagRuleConfigId: id
					})

					this.tagList = res.Result.TagRuleConfigTagDetailList.map(item => {
						item.Name = item.TagDetailName;
						return item
					})

					this.ruleForm.name = res.Result.TagRuleConfig.Name;
					this.ruleForm.UpdateWay = res.Result.TagRuleConfig.UpdateWay;
					this.ruleForm.AutoUpdatePeriodType = res.Result.TagRuleConfig.AutoUpdatePeriodType;
					this.ruleForm.AutoUpdatePeriodNum = res.Result.TagRuleConfig.AutoUpdatePeriodNum;
					this.list = res.Result.TagRuleConfig.TagRuleConfigDetailList;

				} catch (e) {
					//TODO handle the exception
				}
			},
			
			handleSave() {


				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						
						this.$refs['tagMod'].validForm().then(list=>{
							
							if (!this.tagList.length) {
								this.$message.error('请选择标签')
								return;
							}
							if (!list.length) {
								this.$message.error('请完善自动打标签规则配置')
								return;
							}
							
							this.saveList = list;
							this.sureSave();
						})
					}
				});


			},
			
			async sureSave() {
				
				var params = {
					Name: this.ruleForm.name,
					UpdateWay: this.ruleForm.UpdateWay,
					AutoUpdatePeriodType: this.ruleForm.AutoUpdatePeriodType,
					AutoUpdatePeriodNum: this.ruleForm.AutoUpdatePeriodNum,
					TagRuleConfigDetailList: this.saveList
				}

				try {
					const res = await tagRuleConfigedit({
						TagRuleConfig: params,
						TagRuleConfigTagDetailList: this.tagList.map(item => {
							return {
								TagRuleConfigId: 0,
								TagGroupId: item.TagGroupId,
								TagDetailId: item.TagDetailId,
								TagDetailName: item.TagGroupName,
							}
						})
					});

					this.$router.replace({
						name: 'autoAddTag'
					})

				} catch (e) {
					//TODO handle the exception
				}
			},
			handleSelect(list) {
				console.log(list)
				this.tagList = list;
				this.addTagVisible = false;
			},
			handleCloseTag(index) {
				this.tagList.splice(index, 1)
			},
			handleBack() {
				this.$router.replace({
					name: 'autoAddTag'
				})
			},

			//推荐规则
			handleUse(data) {
				this.list = [];
				this.$nextTick(() => {
					this.list = JSON.parse(JSON.stringify(data));
					this.ruleVisible = false;

					var ids = this.list.map(item => {
						return item.id
					})
					this.menuList1.map(item => {
						item.checked = ids.indexOf(item.id) > -1;
						return item;
					})
					this.menuList2.map(item => {
						item.checked = ids.indexOf(item.id) > -1;
						return item;
					})
				})
			},
			getNormalRuleList() {
				this.getRule1();
				this.getRule2();
				this.getRule3();
				this.getRule4();
				this.getRule5();
				this.getRule6();
				this.getRule7();
			},
			//为近30天内，未支付订单的客户打标签
			getRule1() {
				this.normalList.push({
					title: '近30天内，未支付订单的客户打标签',
					data: [{
						list: [{
							time: this.getInTime(30),
							total: 0,
							value: 5
						}],
						id: 10,
						value: "累计支付笔数",
						checked: true,
						type: 10
					}]
				})
			},
			//为近30天内，有支付成功过1次的客户打标签
			getRule2() {
				this.normalList.push({
					title: '近30天内，有支付成功过1次的客户打标签',
					data: [{
						list: [{
							time: this.getInTime(30),
							total: 1,
							value: 2
						}],
						id: 10,
						value: "累计支付笔数",
						checked: true,
						type: 10
					}]
				})
			},
			//为近15天内，有支付成功过1次，并且还不是会员的客户打标签
			getRule3() {
				this.normalList.push({
					title: '近15天内，有支付成功过1次，并且还不是会员的客户打标签',
					data: [{
							check: [
								0
							],
							id: 2,
							value: "会员级别",
							checked: true,
							type: 2
						},
						{
							list: [{
								time: this.getInTime(15),
								total: 1,
								value: 2
							}],
							id: 10,
							value: "累计支付笔数",
							checked: true,
							type: 10
						}
					]

				})
			},
			//为近60天内，有支付成功过1次，但是近30天内没有未支付成功的客户打标签
			getRule4() {
				this.normalList.push({
					title: '近60天内，有支付成功过1次，但是近30天内没有未支付成功的客户打标签',
					data: [{
						list: [{
								time: this.getInTime(60),
								total: 1,
								value: 2
							},
							{
								time: this.getInTime(30),
								total: 0,
								value: 5
							}
						],
						id: 10,
						value: "累计支付笔数",
						checked: true,
						type: 10
					}]
				})
			},
			//为近90天内，有支付成功订单，并且支付订单总笔数＞3笔的客户打标签
			getRule5() {
				this.normalList.push({
					title: '近90天内，有支付成功订单，并且支付订单总笔数＞3笔的客户打标签',
					data: [{
						list: [{
							time: this.getInTime(90),
							total: 3,
							value: 1
						}],
						id: 10,
						value: "累计支付笔数",
						checked: true,
						type: 10
					}]
				})
			},
			//为商城上线至今天，消费总金额＞=500元的客户打标签
			getRule6() {
				this.normalList.push({
					title: '商城上线至今，消费总金额＞=500元的客户打标签',
					data: [{
						list: [{
							time: [
								this.mallInfo.AddTime.split(' ')[0],
								this.getTodayDate()
							],
							total: 500,
							value: 2
						}],
						id: 11,
						value: "累计支付金额",
						checked: true,
						type: 11
					}]
				})
			},
			//为商城上线到昨天，累计充值金额＞=1000元的客户打标签
			getRule7() {
				this.normalList.push({
					title: '商城上线至今，累计充值金额＞=1000元的客户打标签',
					data: [{
						list: [{
							time: [
								this.mallInfo.AddTime.split(' ')[0],
								this.getTodayDate()
							],
							total: 1000,
							value: 2
						}],
						id: 12,
						value: "累计充值余额",
						checked: true,
						type: 12
					}]
				})
			},


			getInTime(days) {
				var year = new Date().getFullYear();
				var month = new Date().getMonth();
				var day = new Date().getDate();

				return [this.getTimeStr(new Date(year, month, day - days)), this.getTimeStr(new Date())]

			},
			
			//将时间转换为字符串类型
			getTimeStr(date) {
				var year = date.getFullYear();
				var month = date.getMonth() + 1;
				var day = date.getDate();

				return [
					year,
					month > 9 ? month : ('0' + month),
					day > 9 ? day : ('0' + day),
				].join('-')
			},
			
			//获取昨天时间（string）
			getYesterdayDate() {
				var year = new Date().getFullYear();
				var month = new Date().getMonth();
				var day = new Date().getDate();

				var newDate = new Date(year, month, day - 1);

				var year = newDate.getFullYear();
				var month = newDate.getMonth() + 1;
				var day = newDate.getDate();

				return [
					year,
					month > 9 ? month : ('0' + month),
					day > 9 ? day : ('0' + day),
				].join('-')
			},
			
			//获取今天时间（string）
			getTodayDate() {
				var year = new Date().getFullYear();
				var month = new Date().getMonth() + 1;
				var day = new Date().getDate();

				return [
					year,
					month > 9 ? month : ('0' + month),
					day > 9 ? day : ('0' + day),
				].join('-')
			},
			
			//将天数+1
			formatNextDate(dateStr){
				var date = dateStr.split('-');
				var year = date[0];
				var month = +date[1] - 1;
				var day = +date[2];
				
				return this.getTimeStr(new Date(year, month,day+1))
			},
			//将天数-1
			formatPrevDate(dateStr){
				var date = dateStr.split('-');
				var year = date[0];
				var month = +date[1] - 1;
				var day = +date[2];
				
				return this.getTimeStr(new Date(year, month,day-1))
			}
		}
	}
</script>

<style type="text/css" lang="less">
	.auto-add-tag-edit{
		.el-form-item__error{
			white-space: nowrap;
		}
	}
</style>
<style lang="less" scoped>
	.container {
		font-size: 14px;
		padding-bottom: 70px;
		
		.rule-title {
			font-size: 13px;
			color: #999;
			margin-bottom: 20px;
		}

		.rule-item {
			display: flex;
			justify-content: space-between;
			font-size: 14px;
			margin-bottom: 20px;
			color: #666;
		}

		
		.footer {
			position: fixed;
			left: 0;
			bottom: 0;
			width: 100%;
			padding: 10px 0;
			text-align: center;
			background-color: #fff;
			z-index: 100;
		}
	}
	
	.card-box{
		// position: absolute;
		// left: 10px;
		// top: 10px;
		// right: 10px;
		// bottom: 70px;
		
		// ::v-deep .el-card__body{
		// 	position: absolute;
		// 	left: 0;
		// 	top: 0;
		// 	width: 100%;
		// 	height: 100%;
			
		// 	display: flex;
		// 	flex-direction: column;
		// }
		
		// .demo-ruleForm{
		// 	flex: 0 0 auto;
		// }
		
		// .form1{
		// 	flex: 0 0 auto;
		// }
	}
	
	.tag-wraper{
		height: 600px;
		display: flex;
		margin-left: 40px;
	}
</style>
